import React from 'react';

enum Role {
    Agent = 'agent',
    User = 'user'
}

interface TranscriptMessageProps {
    message: string;
    role: Role;
    speaker: string;
}

const TranscriptMessage = React.memo(({ message, role, speaker }: TranscriptMessageProps) => {

    return (
        <div className="d-flex flex-col" style={{ marginBottom: '24px' }}>
            <h4 className={`font-inter ${role === Role.Agent ? 'text-left' : 'text-right'}`} style={{ marginBottom: '8px' }}>{speaker}</h4>
            <div className={`transcript-bubble d-flex ${role === Role.Agent ? 'interviewer' : 'candidate'}`} style={{ padding: '16px', borderRadius: '16px', width: 'fit-content' }}>
                <p className="font-inter">{message}</p>
            </div>
        </div>
    );
});

export default TranscriptMessage; 