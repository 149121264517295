import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Box, Typography, Container, Paper, CircularProgress } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import Logo from './dynamic_session_logo.svg';
import axios from 'axios';

var proxyEndpoint = process.env.REACT_APP_PROXY_ENDPOINT || 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var env = String(process.env.REACT_APP_ENV)
console.log('env', env)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
}

const DynamicSession = () => {
  const { interviewId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${proxyEndpoint}/sessions`, {
        interviewConfigId: interviewId,
        candidateFirstName: formData.firstName,
        candidateLastName: formData.lastName,
        candidateEmail: formData.email,
        candidatePhone: formData.phone,
        callType: 'web',
        dynamicSession: true
      });

      // Redirect to the session page
      if (formData.phone) {
        window.location.href = `/phone?session=${response.data}`;
      } else {
        window.location.href = `/?session=${response.data}`;
      }
    } catch (err) {
      setError('Failed to create session. Please try again.');
      console.error('Error creating session:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
        borderBottom: '1px solid #D3D3D3',
        width: '100%',
        paddingBottom: '24px'
      }}>
        <a href="https://wayfaster.com">
          <img src={Logo} alt="Logo" style={{ width: '152px', height: '32px' }} />
        </a>
      </Box>
      <Box sx={{ mt: 8, width: '50%', margin: 'auto', background: '#F9F9F9', height: '100vh', width: '100%', paddingTop: '100px' }}>
        <Paper sx={{ p: 4, borderRadius: '8px', border: '1px solid #E0E0E0', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)', width: '400px', margin: 'auto' }}>
          <h4 style={{ 
            fontSize: '24px',
            fontFamily: 'PPNeueMontreal',
            fontWeight: 600,
            marginBottom: '24px',
          }}>
            Enter Your Details to Start the Interview
          </h4>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              required
              name="firstName" 
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              margin="normal"
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                },
                '& .MuiInputBase-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                }
              }}
            />
            <TextField
              fullWidth
              required
              name="lastName"
              label="Last Name" 
              value={formData.lastName}
              onChange={handleChange}
              margin="normal"
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                },
                '& .MuiInputBase-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                }
              }}
            />
            <p style={{
              fontFamily: 'PPNeueMontreal',
              fontSize: '14px',
              color: '#475467',
              marginBottom: '8px'
            }}>
              <InfoOutlined sx={{fontSize: 16, marginRight: 1, verticalAlign: 'text-bottom'}} /> Please provide either an email address or phone number
            </p>
            <TextField
              fullWidth
              name="email"
              label="Email"
              type="email" 
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required={!formData.phone}
              disabled={!!formData.phone}
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                },
                '& .MuiInputBase-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                },
                '& .MuiFormHelperText-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '12px'
                }
              }}
            />
            <TextField
              fullWidth
              name="phone"
              label="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
              required={!formData.email}
              disabled={!!formData.email}
              helperText="Format: +1XXXXXXXXXX"
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                },
                '& .MuiInputBase-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px'
                },
                '& .MuiFormHelperText-root': {
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '12px'
                }
              }}
            />
            {error && (
              <p style={{ 
                color: 'var(--color-error)',
                fontFamily: 'PPNeueMontreal',
                fontSize: '14px',
                marginTop: '16px'
              }}>
                {error}
              </p>
            )}
            <button 
              type="submit"
              className="btn-main"
              style={{
                width: '100%',
                marginTop: '16px',
                padding: '12px 20px',
                fontSize: '14px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: (loading || (!formData.email && !formData.phone) || 
                  (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) ||
                  (formData.phone && !/^\+1\d{10}$/.test(formData.phone))) ? 0.5 : 1,
                cursor: (loading || (!formData.email && !formData.phone) ||
                  (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) ||
                  (formData.phone && !/^\+1\d{10}$/.test(formData.phone))) ? 'not-allowed' : 'pointer'
              }}
              disabled={loading || (!formData.email && !formData.phone) ||
                (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) ||
                (formData.phone && !/^\+1\d{10}$/.test(formData.phone))}
            >
              {loading ? (
                <>
                  <CircularProgress size={14} style={{ color: "white", marginRight: "8px" }}/>
                  Creating Session...
                </>
              ) : "Start Interview"}
            </button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default DynamicSession; 