// env mapping
var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
var env = String(process.env.REACT_APP_ENV)

// RETELL ENDPOINTS
var retellApiEndpoint = 'https://retell-main-proxy-69440b772c45.herokuapp.com'
var retellWsEndpoint = 'wss://retell-main-proxy-69440b772c45.herokuapp.com'

// Voice agent IDs
var defaultVoiceId = '11labs-Jason'
var defaultLanguage = 'English'

var RETELL_AGENT_ID_JASON_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_JASON_CHINESE_PRODUCTION
var RETELL_AGENT_ID_MYRA_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_CHINESE_PRODUCTION
var RETELL_AGENT_ID_EVIE_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE_CHINESE_PRODUCTION

var RETELL_AGENT_ID_JASON_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_JASON_FRENCH_PRODUCTION
var RETELL_AGENT_ID_MYRA_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_FRENCH_PRODUCTION
var RETELL_AGENT_ID_GILFOY_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_GILFOY_FRENCH_PRODUCTION

var RETELL_AGENT_ID_JASON_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_JASON_GERMAN_PRODUCTION
var RETELL_AGENT_ID_MYRA_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_GERMAN_PRODUCTION
var RETELL_AGENT_ID_CAROLA_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_CAROLA_GERMAN_PRODUCTION

var RETELL_AGENT_ID_JASON_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_JASON_HINDI_PRODUCTION
var RETELL_AGENT_ID_MYRA_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_HINDI_PRODUCTION
var RETELL_AGENT_ID_SAMAD_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_SAMAD_HINDI_PRODUCTION
var RETELL_AGENT_ID_MONIKA_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_MONIKA_HINDI_PRODUCTION

var RETELL_AGENT_ID_JASON_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_JASON_SPANISH_PRODUCTION
var RETELL_AGENT_ID_MYRA_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_SPANISH_PRODUCTION
var RETELL_AGENT_ID_SANTIAGO_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_SANTIAGO_SPANISH_PRODUCTION

var RETELL_AGENT_ID_JASON = process.env.REACT_APP_RETELL_AGENT_ID_JASON_PRODUCTION
var RETELL_AGENT_ID_DOROTHY = process.env.REACT_APP_RETELL_AGENT_ID_DOROTHY_PRODUCTION
var RETELL_AGENT_ID_NOVA = process.env.REACT_APP_RETELL_AGENT_ID_NOVA_PRODUCTION
var RETELL_AGENT_ID_ANTHONY = process.env.REACT_APP_RETELL_AGENT_ID_ANTHONY_PRODUCTION
var RETELL_AGENT_ID_LILY = process.env.REACT_APP_RETELL_AGENT_ID_LILY_PRODUCTION
var RETELL_AGENT_ID_MARISSA = process.env.REACT_APP_RETELL_AGENT_ID_MARISSA_PRODUCTION
var RETELL_AGENT_ID_BRIAN = process.env.REACT_APP_RETELL_AGENT_ID_BRIAN_PRODUCTION
var RETELL_AGENT_ID_KATE = process.env.REACT_APP_RETELL_AGENT_ID_KATE_PRODUCTION
var RETELL_AGENT_ID_FABLE = process.env.REACT_APP_RETELL_AGENT_ID_FABLE_PRODUCTION
var RETELL_AGENT_ID_PAUL = process.env.REACT_APP_RETELL_AGENT_ID_PAUL_PRODUCTION
var RETELL_AGENT_ID_JOE = process.env.REACT_APP_RETELL_AGENT_ID_JOE_PRODUCTION
var RETELL_AGENT_ID_ADRIAN = process.env.REACT_APP_RETELL_AGENT_ID_ADRIAN_PRODUCTION
var RETELL_AGENT_ID_ZURI = process.env.REACT_APP_RETELL_AGENT_ID_ZURI_PRODUCTION
var RETELL_AGENT_ID_CHLOE = process.env.REACT_APP_RETELL_AGENT_ID_CHLOE_PRODUCTION
var RETELL_AGENT_ID_MAX = process.env.REACT_APP_RETELL_AGENT_ID_MAX_PRODUCTION
var RETELL_AGENT_ID_JULIA = process.env.REACT_APP_RETELL_AGENT_ID_JULIA_PRODUCTION
var RETELL_AGENT_ID_SUSAN = process.env.REACT_APP_RETELL_AGENT_ID_SUSAN_PRODUCTION
var RETELL_AGENT_ID_KATHRINE = process.env.REACT_APP_RETELL_AGENT_ID_KATHRINE_PRODUCTION
var RETELL_AGENT_ID_NINA = process.env.REACT_APP_RETELL_AGENT_ID_NINA_PRODUCTION
var RETELL_AGENT_ID_MYRA = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_PRODUCTION
var RETELL_AGENT_ID_PAOLA = process.env.REACT_APP_RETELL_AGENT_ID_PAOLA_PRODUCTION
var RETELL_AGENT_ID_EVIE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE_PRODUCTION
var RETELL_AGENT_ID_JAMES = process.env.REACT_APP_RETELL_AGENT_ID_JAMES_PRODUCTION
var RETELL_AGENT_ID_RYAN = process.env.REACT_APP_RETELL_AGENT_ID_RYAN_PRODUCTION
var RETELL_AGENT_ID_MIA = process.env.REACT_APP_RETELL_AGENT_ID_MIA_PRODUCTION

if (env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
  retellApiEndpoint = 'https://staging-core-voice-api-2ac3e4de69f0.herokuapp.com'

  RETELL_AGENT_ID_JASON_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_JASON_CHINESE_STAGING
  RETELL_AGENT_ID_MYRA_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_CHINESE_STAGING
  RETELL_AGENT_ID_EVIE_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE_CHINESE_STAGING

  RETELL_AGENT_ID_JASON_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_JASON_FRENCH_STAGING
  RETELL_AGENT_ID_MYRA_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_FRENCH_STAGING
  RETELL_AGENT_ID_GILFOY_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_GILFOY_FRENCH_STAGING

  RETELL_AGENT_ID_JASON_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_JASON_GERMAN_STAGING
  RETELL_AGENT_ID_MYRA_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_GERMAN_STAGING
  RETELL_AGENT_ID_CAROLA_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_CAROLA_GERMAN_STAGING

  RETELL_AGENT_ID_JASON_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_JASON_HINDI_STAGING
  RETELL_AGENT_ID_MYRA_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_HINDI_STAGING
  RETELL_AGENT_ID_SAMAD_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_SAMAD_HINDI_STAGING
  RETELL_AGENT_ID_MONIKA_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_MONIKA_HINDI_STAGING

  RETELL_AGENT_ID_JASON_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_JASON_SPANISH_STAGING
  RETELL_AGENT_ID_MYRA_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_SPANISH_STAGING
  RETELL_AGENT_ID_SANTIAGO_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_SANTIAGO_SPANISH_STAGING

  RETELL_AGENT_ID_JASON = process.env.REACT_APP_RETELL_AGENT_ID_JASON_STAGING
  RETELL_AGENT_ID_DOROTHY = process.env.REACT_APP_RETELL_AGENT_ID_DOROTHY_STAGING
  RETELL_AGENT_ID_NOVA = process.env.REACT_APP_RETELL_AGENT_ID_NOVA_STAGING
  RETELL_AGENT_ID_ANTHONY = process.env.REACT_APP_RETELL_AGENT_ID_ANTHONY_STAGING
  RETELL_AGENT_ID_LILY = process.env.REACT_APP_RETELL_AGENT_ID_LILY_STAGING
  RETELL_AGENT_ID_MARISSA = process.env.REACT_APP_RETELL_AGENT_ID_MARISSA_STAGING
  RETELL_AGENT_ID_BRIAN = process.env.REACT_APP_RETELL_AGENT_ID_BRIAN_STAGING
  RETELL_AGENT_ID_KATE = process.env.REACT_APP_RETELL_AGENT_ID_KATE_STAGING
  RETELL_AGENT_ID_FABLE = process.env.REACT_APP_RETELL_AGENT_ID_FABLE_STAGING
  RETELL_AGENT_ID_PAUL = process.env.REACT_APP_RETELL_AGENT_ID_PAUL_STAGING
  RETELL_AGENT_ID_JOE = process.env.REACT_APP_RETELL_AGENT_ID_JOE_STAGING
  RETELL_AGENT_ID_ADRIAN = process.env.REACT_APP_RETELL_AGENT_ID_ADRIAN_STAGING
  RETELL_AGENT_ID_ZURI = process.env.REACT_APP_RETELL_AGENT_ID_ZURI_STAGING
  RETELL_AGENT_ID_CHLOE = process.env.REACT_APP_RETELL_AGENT_ID_CHLOE_STAGING
  RETELL_AGENT_ID_MAX = process.env.REACT_APP_RETELL_AGENT_ID_MAX_STAGING
  RETELL_AGENT_ID_JULIA = process.env.REACT_APP_RETELL_AGENT_ID_JULIA_STAGING
  RETELL_AGENT_ID_SUSAN = process.env.REACT_APP_RETELL_AGENT_ID_SUSAN_STAGING
  RETELL_AGENT_ID_KATHRINE = process.env.REACT_APP_RETELL_AGENT_ID_KATHRINE_STAGING
  RETELL_AGENT_ID_NINA = process.env.REACT_APP_RETELL_AGENT_ID_NINA_STAGING
  RETELL_AGENT_ID_MYRA = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_STAGING
  RETELL_AGENT_ID_PAOLA = process.env.REACT_APP_RETELL_AGENT_ID_PAOLA_STAGING
  RETELL_AGENT_ID_EVIE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE_STAGING
  RETELL_AGENT_ID_JAMES = process.env.REACT_APP_RETELL_AGENT_ID_JAMES_STAGING
  RETELL_AGENT_ID_RYAN = process.env.REACT_APP_RETELL_AGENT_ID_RYAN_STAGING
  RETELL_AGENT_ID_MIA = process.env.REACT_APP_RETELL_AGENT_ID_MIA_STAGING
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
  retellApiEndpoint = 'http://localhost:8080'
  retellWsEndpoint = 'ws://localhost:8080'

  RETELL_AGENT_ID_JASON_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_JASON_CHINESE
  RETELL_AGENT_ID_MYRA_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_CHINESE
  RETELL_AGENT_ID_EVIE_CHINESE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE_CHINESE

  RETELL_AGENT_ID_JASON_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_JASON_FRENCH
  RETELL_AGENT_ID_MYRA_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_FRENCH
  RETELL_AGENT_ID_GILFOY_FRENCH = process.env.REACT_APP_RETELL_AGENT_ID_GILFOY_FRENCH

  RETELL_AGENT_ID_JASON_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_JASON_GERMAN
  RETELL_AGENT_ID_MYRA_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_GERMAN
  RETELL_AGENT_ID_CAROLA_GERMAN = process.env.REACT_APP_RETELL_AGENT_ID_CAROLA_GERMAN

  RETELL_AGENT_ID_JASON_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_JASON_HINDI
  RETELL_AGENT_ID_MYRA_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_HINDI
  RETELL_AGENT_ID_SAMAD_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_SAMAD_HINDI
  RETELL_AGENT_ID_MONIKA_HINDI = process.env.REACT_APP_RETELL_AGENT_ID_MONIKA_HINDI

  RETELL_AGENT_ID_JASON_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_JASON_SPANISH
  RETELL_AGENT_ID_MYRA_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_MYRA_SPANISH
  RETELL_AGENT_ID_SANTIAGO_SPANISH = process.env.REACT_APP_RETELL_AGENT_ID_SANTIAGO_SPANISH

  RETELL_AGENT_ID_JASON = process.env.REACT_APP_RETELL_AGENT_ID_JASON
  RETELL_AGENT_ID_DOROTHY = process.env.REACT_APP_RETELL_AGENT_ID_DOROTHY
  RETELL_AGENT_ID_NOVA = process.env.REACT_APP_RETELL_AGENT_ID_NOVA
  RETELL_AGENT_ID_ANTHONY = process.env.REACT_APP_RETELL_AGENT_ID_ANTHONY
  RETELL_AGENT_ID_LILY = process.env.REACT_APP_RETELL_AGENT_ID_LILY
  RETELL_AGENT_ID_MARISSA = process.env.REACT_APP_RETELL_AGENT_ID_MARISSA
  RETELL_AGENT_ID_BRIAN = process.env.REACT_APP_RETELL_AGENT_ID_BRIAN
  RETELL_AGENT_ID_KATE = process.env.REACT_APP_RETELL_AGENT_ID_KATE
  RETELL_AGENT_ID_FABLE = process.env.REACT_APP_RETELL_AGENT_ID_FABLE
  RETELL_AGENT_ID_PAUL = process.env.REACT_APP_RETELL_AGENT_ID_PAUL
  RETELL_AGENT_ID_JOE = process.env.REACT_APP_RETELL_AGENT_ID_JOE
  RETELL_AGENT_ID_ADRIAN = process.env.REACT_APP_RETELL_AGENT_ID_ADRIAN
  RETELL_AGENT_ID_ZURI = process.env.REACT_APP_RETELL_AGENT_ID_ZURI
  RETELL_AGENT_ID_CHLOE = process.env.REACT_APP_RETELL_AGENT_ID_CHLOE
  RETELL_AGENT_ID_MAX = process.env.REACT_APP_RETELL_AGENT_ID_MAX
  RETELL_AGENT_ID_JULIA = process.env.REACT_APP_RETELL_AGENT_ID_JULIA
  RETELL_AGENT_ID_SUSAN = process.env.REACT_APP_RETELL_AGENT_ID_SUSAN
  RETELL_AGENT_ID_KATHRINE = process.env.REACT_APP_RETELL_AGENT_ID_KATHRINE
  RETELL_AGENT_ID_NINA = process.env.REACT_APP_RETELL_AGENT_ID_NINA
  RETELL_AGENT_ID_MYRA = process.env.REACT_APP_RETELL_AGENT_ID_MYRA
  RETELL_AGENT_ID_PAOLA = process.env.REACT_APP_RETELL_AGENT_ID_PAOLA
  RETELL_AGENT_ID_EVIE = process.env.REACT_APP_RETELL_AGENT_ID_EVIE
  RETELL_AGENT_ID_JAMES = process.env.REACT_APP_RETELL_AGENT_ID_JAMES
  RETELL_AGENT_ID_RYAN = process.env.REACT_APP_RETELL_AGENT_ID_RYAN
  RETELL_AGENT_ID_MIA = process.env.REACT_APP_RETELL_AGENT_ID_MIA
}

var agentMappingEnglish = {
  '11labs-Jason': {'id': RETELL_AGENT_ID_JASON, 'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Dorothy': {'id': RETELL_AGENT_ID_DOROTHY, 'name': 'Dorothy', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Dorothy.png'},
  'openai-Nova': {'id': RETELL_AGENT_ID_NOVA, 'name': 'Nova', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nova.png'},
  '11labs-Anthony': {'id': RETELL_AGENT_ID_ANTHONY, 'name': 'Anthony', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anthony.png'},
  '11labs-Lily': {'id': RETELL_AGENT_ID_LILY, 'name': 'Lily', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lily.png'},
  '11labs-Marissa': {'id': RETELL_AGENT_ID_MARISSA, 'name': 'Marissa', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Marissa.png'},
  '11labs-Brian': {'id': RETELL_AGENT_ID_BRIAN, 'name': 'Brian', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/brian.png'},
  '11labs-Kate': {'id': RETELL_AGENT_ID_KATE, 'name': 'Kate', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Kate.png'},
  'openai-Fable': {'id': RETELL_AGENT_ID_FABLE, 'name': 'Fable', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/fable.png'},
  '11labs-Paul': {'id': RETELL_AGENT_ID_PAUL, 'name': 'Paul', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/paul.png'},
  '11labs-Joe': {'id': RETELL_AGENT_ID_JOE, 'name': 'Joe', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/joe.png'},
  '11labs-Adrian': {'id': RETELL_AGENT_ID_ADRIAN, 'name': 'Adrian', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/adrian.png'},
  '11labs-Zuri': {'id': RETELL_AGENT_ID_ZURI, 'name': 'Zuri', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Zuri.png'},
  '11labs-Chloe': {'id': RETELL_AGENT_ID_CHLOE, 'name': 'Chloe', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Chloe.png'},
  '11labs-Max': {'id': RETELL_AGENT_ID_MAX, 'name': 'Max', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/max.png'},
  '11labs-Julia': {'id': RETELL_AGENT_ID_JULIA, 'name': 'Julia', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Julia.png'},
  '11labs-Susan': {'id': RETELL_AGENT_ID_SUSAN, 'name': 'Susan', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Susan.png'},
  '11labs-Kathrine': {'id': RETELL_AGENT_ID_KATHRINE, 'name': 'Kathrine', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/kathrine.png'},
  '11labs-Nina': {'id': RETELL_AGENT_ID_NINA, 'name': 'Nina', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nina.png'},
  '11labs-Myra': {'id': RETELL_AGENT_ID_MYRA, 'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Paola': {'id': RETELL_AGENT_ID_PAOLA, 'name': 'Paola', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Paola.png'},
  '11labs-Evie': {'id': RETELL_AGENT_ID_EVIE, 'name': 'Evie', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/evie.png'},
  '11labs-James': {'id': RETELL_AGENT_ID_JAMES, 'name': 'James', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/james.png'},
  '11labs-Ryan': {'id': RETELL_AGENT_ID_RYAN, 'name': 'Ryan', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ryan.png'},
  '11labs-Mia': {'id': RETELL_AGENT_ID_MIA, 'name': 'Mia', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/mia.png'}
}

var agentMappingChinese = {
  '11labs-Jason': {'id': RETELL_AGENT_ID_JASON_CHINESE, 'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'id': RETELL_AGENT_ID_MYRA_CHINESE, 'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Evie': {'id': RETELL_AGENT_ID_EVIE_CHINESE, 'name': 'Evie', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/evie.png'},
}
var agentMappingFrench = {
  '11labs-Jason': {'id': RETELL_AGENT_ID_JASON_FRENCH, 'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'id': RETELL_AGENT_ID_MYRA_FRENCH, 'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Gilfoy': {'id': RETELL_AGENT_ID_GILFOY_FRENCH, 'name': 'Gilfoy', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/gilfoy.png'},
}
var agentMappingGerman = {
  '11labs-Jason': {'id': RETELL_AGENT_ID_JASON_GERMAN, 'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'id': RETELL_AGENT_ID_MYRA_GERMAN, 'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Carola': {'id': RETELL_AGENT_ID_CAROLA_GERMAN, 'name': 'Carola', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/carola.png'},
}
var agentMappingHindi = {
  '11labs-Jason': {'id': RETELL_AGENT_ID_JASON_HINDI, 'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'id': RETELL_AGENT_ID_MYRA_HINDI, 'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Samad': {'id': RETELL_AGENT_ID_SAMAD_HINDI, 'name': 'Samad', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/samad.png'},
  '11labs-Monika': {'id': RETELL_AGENT_ID_MONIKA_HINDI, 'name': 'Monika', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/monika.png'},
}
var agentMappingSpanish = {
  '11labs-Jason': {'id': RETELL_AGENT_ID_JASON_SPANISH, 'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'id': RETELL_AGENT_ID_MYRA_SPANISH, 'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Santiago': {'id': RETELL_AGENT_ID_SANTIAGO_SPANISH, 'name': 'Santiago', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/santiago.png'},
}

var languageAgentMapping = {
  'English': agentMappingEnglish,
  'Chinese': agentMappingChinese,
  'French': agentMappingFrench,
  'German': agentMappingGerman,
  'Hindi': agentMappingHindi,
  'Spanish': agentMappingSpanish,
}

export { proxyEndpoint, wsEndpoint, vapiEnvId, retellApiEndpoint, retellWsEndpoint, defaultVoiceId, defaultLanguage, languageAgentMapping }