import React from 'react';

interface VideoFeedProps {
    videoStream: MediaStream | null;
    speaker: string;
}

const VideoFeed = React.memo(({ videoStream, speaker }: VideoFeedProps) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(() => {
        if (videoRef.current && videoStream) {
            videoRef.current.srcObject = videoStream;
        }
    }, [videoStream]);

    if (!videoStream) return null;

    return (
        <div className="d-flex my-auto" style={{
            width: '100%',
            height: 'fit-content',
            aspectRatio: '16 / 9',
            borderRadius: '16px',
            border: '1px solid var(--color-background-dark)',
            objectFit: 'cover',
            position: 'relative',
            zIndex: 1000
        }}>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                className="video-feed-mirrored my-auto"
                style={{
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '16 / 9',
                    borderRadius: '16px',
                    border: '1px solid var(--color-background-dark)',
                    backgroundColor: '#F8F8F8',
                    objectFit: 'cover',
                    position: 'relative',
                    zIndex: 1000
                }}
            >
            </video>
            <h4 style={{ color: 'white', position: 'absolute', bottom: 16, left: 16, zIndex: 1001 }}>{speaker}</h4>
        </div>
    );
});

export default VideoFeed; 